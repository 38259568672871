import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import batukLogo from "../assets/images/Batuk Logo.png";
import BatukLogo from "../images/Batuk-logo.png";
import notificationLogo from "../assets/Icons/Notification.png";
import faqLogo from "../assets/Icons/Faq.png";
import MenuBar from "../assets/Icons/menuicon.png";
import SignOutBlueLogo from "../assets/Icons/Sign out 2.png";
import ProfileLogo from "../assets/Icons/ProfileIcon.png";
import GoldImg from "../assets/Icons/GoldIcon.png";
import SilverImg from "../assets/Icons/silverIcon.png";
import Home from "../assets/Icons/Home.png";
import HomeHover from "../assets/Icons/Home 2.png";
import Earning from "../assets/Icons/3d earnings.png";
import EarningHover from "../assets/Icons/3d earnings 3.png";
import Client from "../assets/Icons/Client lists.png";
import ClientHover from "../assets/Icons/Client Lists 2.png";
import Employees from "../assets/Icons/Employees.png";
import EmployeesHover from "../assets/Icons/Employees 2.png";
import Sip from "../assets/Icons/Sip.png";
import SIPHover from "../assets/Icons/Sip 2.png";
import Fd from "../assets/Icons/Gold FD.png";
import FDHover from "../assets/Icons/Gold FD 2.png";
import Proposal from "../assets/Icons/Proposal.png";
import SellHover from "../assets/Icons/Proposal 2.png";
import Sell from "../assets/Icons/Proposal.png";
import ProposalHover from "../assets/Icons/Proposal 2.png";
import Reports from "../assets/Icons/Report.png";
import ReportsHover from "../assets/Icons/Report 2.png";
import ImpFiles from "../assets/Icons/Bulk import.png";
import ImpFilesHover from "../assets/Icons/Bulk import 2.png";
import SpotGold from "../assets/Icons/Spot Gold (1).png";
import SpotGoldHover from "../assets/Icons/Spot Gold 2.png";

import { baseUrl } from "../utils/baseUrl";

const settings = ["Profile", "Reset Password", "Logout"];

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   "& .MuiDrawer-paper": {
//     position: "relative",
//     whiteSpace: "nowrap",
//     width: drawerWidth,
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     boxSizing: "border-box",
//     ...(!open && {
//       overflowX: "hidden",
//       transition: theme.transitions.create("width", {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//       }),
//       width: theme.spacing(7),
//       [theme.breakpoints.up("sm")]: {
//         width: theme.spacing(9),
//       },
//     }),
//   },
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "73px", // Set the width to 73px when the drawer is closed
      [theme.breakpoints.up("sm")]: {
        width: "73px", // Ensure consistency across breakpoints
      },
    }),
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  "&:hover .icon": {
    display: "none",
  },
  "&:hover .icon-hover": {
    display: "block",
  },
  ".icon-hover": {
    display: "none",
  },
}));

const defaultTheme = createTheme();

export default function DashBoard() {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [silverData, setSilverData] = React.useState(null);
  const [goldData, setGoldData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [profileData, setProfileData] = React.useState({
    name: "",
    email: "",
  });
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const Token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getProfileData = async () => {
    try {
      const url = `${baseUrl}/profile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const profile = response.data.data[0];
      setProfileData(profile);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const id = "gold";
  const fetchLiveData = async (id, setData) => {
    try {
      const url = `${baseUrl}/liveprice/${id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const data = response.data.data;
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching data for ${id}`, error);
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  const callLogoutAPI = async () => {
    try {
      const url = `${baseUrl}/partner/logout`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();
      if (data.message === "user logged out successfully") {
        navigate("/login");
      } else {
        alert("Issue in logout");
      }
      return data;
    } catch (error) {
      console.error("Error connecting to API", error);
      throw error;
    }
  };

  const handleLogout = () => {
    callLogoutAPI();
    navigate("/");
    sessionStorage.removeItem("token");
  };

  const [hoverStates, setHoverStates] = React.useState({
    home: false,
    clients: false,
    employees: false,
    sip: false,
    goldfd: false,
    proposal: false,
    sell: false,
    earning: false,
    reports: false,
    impfiles: false,
    spotgold: false,
  });

  const [activeItem, setActiveItem] = React.useState({});
  // const [hover, setHover] = React.useState(false);
  // const handleListItemClick = (item) => {
  //   setActiveItem(item);
  // };

  const handleMouseEnter = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  React.useEffect(() => {
    fetchLiveData("silver", setSilverData);
    fetchLiveData("gold", setGoldData);
    getProfileData();
  }, [id]);

  // Check if the current URL is "/client_table"
  React.useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/dashboard") {
      setActiveItem({ home: true });
    } else if (
      pathname === "/dashboard/client_table" ||
      pathname === "/dashboard/client_kyc"
    ) {
      setActiveItem({ clients: true });
    } else if (pathname === "/dashboard/employee_table") {
      setActiveItem({ employees: true });
    } else if (
      pathname === "/dashboard/sip" ||
      pathname === "/dashboard/create-sip" ||
      pathname.startsWith("/dashboard/final-create-sip")
    ) {
      setActiveItem({ sip: true });
    } else if (
      pathname === "/dashboard/gold-fd" ||
      pathname === "/dashboard/start-gold-fd" ||
      pathname.startsWith("/dashboard/create-gold-fd")
    ) {
      setActiveItem({ GOLDFD: true });
    } else if (
      pathname === "/dashboard/proposal" ||
      pathname === "/dashboard/create_proposal" ||
      pathname === "/dashboard/final_create"
    ) {
      setActiveItem({ proposal: true });
    } else if (
      pathname === "/dashboard/sell" ||
      pathname === "/dashboard/create_sell" ||
      pathname === "/dashboard/final_create_sell"
    ) {
      setActiveItem({ sell: true });
    } else if (pathname === "/dashboard/earning") {
      setActiveItem({ earning: true });
    } else if (pathname === "/dashboard/reports") {
      setActiveItem({ reports: true });
    } else if (pathname === "/dashboard/upload-file") {
      setActiveItem({ impfiles: true });
    } else if (
      pathname === "/dashboard/spot-gold" ||
      pathname === "/dashboard/create_order" ||
      pathname.startsWith("/dashboard/product")
    ) {
      setActiveItem({ spotgold: true });
    } else {
      setActiveItem({});
    }
  }, [location.pathname]);

  const navColor = "#4a2a7d";

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} onClose={toggleDrawer}>
          <Toolbar disableGutters sx={{ backgroundColor: "#472878" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <img
                  src={MenuBar}
                  alt="batuk"
                  height="42px"
                  width="45px"
                  style={{
                    display: "flex",
                    marginRight: 1,
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              ></Menu>
              {!open && (
                <img
                  src={BatukLogo}
                  alt="batuk"
                  width="66px"
                  style={{ display: "flex", marginRight: "1rem" }}
                />
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <h6 style={{ display: "contents", color: "red" }}>Live</h6>
                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>Loading...</div>
                ) : (
                  <div className="livedata">
                    <pre
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0",
                      }}
                    >
                      <img
                        src={GoldImg}
                        alt=""
                        srcSet=""
                        style={{ width: "1rem" }}
                      />{" "}
                      ₹{goldData}/g
                    </pre>
                    <pre
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0",
                      }}
                    >
                      <img
                        src={SilverImg}
                        alt=""
                        srcSet=""
                        style={{ width: "1rem" }}
                      />{" "}
                      ₹{silverData}/g
                    </pre>
                  </div>
                )}
              </div>
            </Box>
            <Box sx={{ flexGrow: 0 }}></Box>
            <Box sx={{ flexGrow: 0, marginRight: "1rem" }}>
              <Tooltip title="Open settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div className="welcome-name">
                    <Typography>Welcome</Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {profileData.name}
                    </Typography>
                  </div>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <img src={ProfileLogo} alt="Profile Logo" width="37px" />
                  </IconButton>
                </div>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div style={{ padding: "8px" }}>
                  <Typography>{profileData.name}</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Partner Code ( {profileData.userName} )
                  </Typography>
                </div>
                <hr style={{ padding: "0px", margin: "0px" }} />
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#4d16a4",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      if (setting === "Logout") {
                        handleLogout();
                      } else if (setting === "Profile") {
                        navigate("/dashboard/profile");
                        handleCloseUserMenu();
                      } else if (setting === "Reset Password") {
                        navigate("/dashboard/reset-password");
                        handleCloseUserMenu();
                      } else {
                        handleCloseUserMenu();
                      }
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{ height: "100vh" }}>
          <DrawerHeader
            sx={{
              backgroundColor: navColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <img
                src={batukLogo}
                alt="batuk"
                width="160px"
                style={{ display: "flex", marginRight: "2rem" }}
              />
            </IconButton>
          </DrawerHeader>
          <List
            style={{ scrollbarWidth: "none" }}
            sx={{
              marginTop: "0px",
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#DCDAFF",
            }}
          >
            <Link
              to="/dashboard"
              onMouseEnter={() => handleMouseEnter("home")}
              onMouseLeave={() => handleMouseLeave("home")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.home ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ home: true })}
                >
                  <ListItemIcon
                    className={activeItem.home ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.home || activeItem.home ? HomeHover : Home
                      }
                      alt="Home Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.home || activeItem.home
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Dashboard
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            <Link
              to="/dashboard/client_table"
              onMouseEnter={() => handleMouseEnter("clients")}
              onMouseLeave={() => handleMouseLeave("clients")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.clients ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ clients: true })}
                >
                  <ListItemIcon
                    className={activeItem.clients ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.clients || activeItem.clients
                          ? ClientHover
                          : Client
                      }
                      alt="Clients Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.clients || activeItem.clients
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Investors
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            {/* Employees Link */}
            <Link
              to="/dashboard/employee_table"
              onMouseEnter={() => handleMouseEnter("employees")}
              onMouseLeave={() => handleMouseLeave("employees")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.employees ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ employees: true })}
                >
                  <ListItemIcon
                    className={activeItem.employees ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.employees || activeItem.employees
                          ? EmployeesHover
                          : Employees
                      }
                      alt="Employees Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.employees || activeItem.employees
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Employees
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            {/* SIP Link */}
            <Link
              to="/dashboard/sip"
              onMouseEnter={() => handleMouseEnter("sip")}
              onMouseLeave={() => handleMouseLeave("sip")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.sip ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ sip: true })}
                >
                  <ListItemIcon
                    className={activeItem.sip ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={hoverStates.sip || activeItem.sip ? SIPHover : Sip}
                      alt="sip Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.sip || activeItem.sip
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        SIP
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            {/* Gold FD Link */}
            {/* <Link
              to="/dashboard/gold-fd"
              onMouseEnter={() => handleMouseEnter("goldfd")}
              onMouseLeave={() => handleMouseLeave("goldfd")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.GOLDFD ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ GOLDFD: true })}
                >
                  <ListItemIcon
                    className={activeItem.GOLDFD ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.goldfd || activeItem.GOLDFD ? FDHover : Fd
                      }
                      alt="Gold FD Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.goldfd || activeItem.GOLDFD
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Gold FD
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link> */}

            {/* <Link to="/tracker">
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    activeItem == "Tracker" ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("Tracker")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={activeItem === "Tracker" ? "activecolor" : ""}
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={Tracker}
                      alt="Profile Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                       className={activeItem == "icon" ? "icon-hover" : ""}
                    />
                    <img
                      src={TrackerHover}
                      alt="Profile Logo Hover"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                       className={activeItem == "icon" ? "icon-hover" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Tracker"
                    className={activeItem === "Tracker" ? "active" : ""}
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link> */}
            <Link
              to="/dashboard/proposal"
              onMouseEnter={() => handleMouseEnter("proposal")}
              onMouseLeave={() => handleMouseLeave("proposal")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.proposal ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ proposal: true })}
                >
                  <ListItemIcon
                    className={activeItem.purposal ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.proposal || activeItem.proposal
                          ? ProposalHover
                          : Proposal
                      }
                      alt="purposal Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.proposal || activeItem.proposal
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Buy Gold / Silver
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/dashboard/sell"
              onMouseEnter={() => handleMouseEnter("sell")}
              onMouseLeave={() => handleMouseLeave("sell")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.sell ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ sell: true })}
                >
                  <ListItemIcon
                    className={activeItem.purposal ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.sell || activeItem.sell ? SellHover : Sell
                      }
                      alt="purposal Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.sell || activeItem.sell
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Sell Gold / Silver
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/dashboard/spot-gold"
              onMouseEnter={() => handleMouseEnter("spotgold")}
              onMouseLeave={() => handleMouseLeave("spotgold")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.spotgold ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ spotgold: true })}
                >
                  <ListItemIcon
                    className={activeItem.spotgold ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.spotgold || activeItem.spotgold
                          ? SpotGoldHover
                          : SpotGold
                      }
                      alt="SpotGold Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.spotgold || activeItem.spotgold
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Jewellery
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/dashboard/earning"
              onMouseEnter={() => handleMouseEnter("earning")}
              onMouseLeave={() => handleMouseLeave("earning")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.earning ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ earning: true })}
                >
                  <ListItemIcon
                    className={activeItem.earning ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.earning || activeItem.earning
                          ? EarningHover
                          : Earning
                      }
                      alt="earning Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.earning || activeItem.earning
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Earnings
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/dashboard/reports"
              onMouseEnter={() => handleMouseEnter("reports")}
              onMouseLeave={() => handleMouseLeave("reports")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.reports ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ reports: true })}
                >
                  <ListItemIcon
                    className={activeItem.reports ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.reports || activeItem.reports
                          ? ReportsHover
                          : Reports
                      }
                      alt="Sales Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.reports || activeItem.reports
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Sales
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/dashboard/upload-file"
              onMouseEnter={() => handleMouseEnter("impfiles")}
              onMouseLeave={() => handleMouseLeave("impfiles")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem.impfiles ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => setActiveItem({ impfiles: true })}
                >
                  <ListItemIcon
                    className={activeItem.impfiles ? "activecolor" : ""}
                    sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
                  >
                    <img
                      src={
                        hoverStates.impfiles || activeItem.impfiles
                          ? ImpFilesHover
                          : ImpFiles
                      }
                      alt="ImpFiles Logo"
                      width="35px"
                      style={{ marginRight: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.impfiles || activeItem.impfiles
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Import Investors
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Main open={open} className="main">
            <Outlet />
          </Main>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
