import React, { useState } from "react";
import InProgressTable from "./Inprogresstable";
import ExecutedTable from "./ExecutedTable";
import ExpiredTable from "./ExpiredTable";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "@mui/material";
import { Col } from "react-bootstrap";

const Proposal = () => {
  const [activeTab, setActiveTab] = useState("InProgress");

  const renderTable = () => {
    switch (activeTab) {
      case "InProgress":
        return <InProgressTable />;
      case "Executed":
        return <ExecutedTable />;
      case "Expired":
        return <ExpiredTable />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  const handleCreatePurposal = async (id) => {
    navigate("/dashboard/create_proposal");
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Gold/Silver Order
            </h2>
          </Col>
          <div
            xs={6}
            style={{ width: "45%", display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              className="btn btn-primary"
              onClick={handleCreatePurposal}
              style={{ textTransform: "capitalize" }}
            >
              + Create Order
            </Button>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-2 p-2 d-flex justify-content-center">
        <div
          className="col-sm-12 d-flex justify-content-center"
          style={{ overflowX: "auto", gap: "2rem" }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              sx: {
                height: "0px",
                overflow: "auto",
              },
            }}
          >
            <Tab
              value="InProgress"
              label="In-Progress"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "InProgress" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="Executed"
              label="Executed"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "Executed" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="Expired"
              label="Expired"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "Expired" ? "#dcdaff" : "inherit",
              }}
            />
          </Tabs>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12">{renderTable()}</div>
      </div>
    </div>
  );
};

export default Proposal;

// import React, { useState } from "react";
// import InProgressTable from "./Inprogresstable";
// import ExecutedTable from "./ExecutedTable";
// import ExpiredTable from "./ExpiredTable";
// import { useNavigate } from "react-router-dom";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import { Button } from "@mui/material";
// import axios from "axios";

// const Proposal = () => {
//   const Token = sessionStorage.getItem("token");
//   const [activeTab, setActiveTab] = useState("InProgress");

//   const renderTable = () => {
//     switch (activeTab) {
//       case "InProgress":
//         return <InProgressTable />;
//       case "Executed":
//         return <ExecutedTable />;
//       case "Expired":
//         return <ExpiredTable />;
//       default:
//         return null;
//     }
//   };

//   const navigate = useNavigate();

//   const handleCreatePurposal = async (id) => {
//     // try {
//     //   const url = `https://app.batuk.in/proposal_create/${id}`;
//     //   const headers = {
//     //     "Content-Type": "application/json",
//     //     Accept: "application/json",
//     //     Authorization: `Bearer ${Token}`,
//     //   };
//     //   const response = await axios.get(url, { headers });

//     //   console.log("prposal create response", response);
//     // } catch (error) {
//     //   console.error("Error connecting to API", error);
//     // }

//     navigate("/create_proposal");
//   };

//   const [value, setValue] = React.useState("InProgress");

//   const handleChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   console.log("value tabs", value);
//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div
//           className="col-md-12 ms-2 mt-2"
//           style={{ display: "flex", justifyContent: "flex-end" }}
//         >
//           <Button
//             variant="contained"
//             className="btn btn-primary"
//             onClick={handleCreatePurposal}
//             style={{ textTransform: "capitalize" }}
//           >
//             + Create Order
//           </Button>
//         </div>
//       </div>

//       <div className="row mt-2 p-2 d-flex justify-content-center">
//         <div
//           className="col-sm-12 d-flex justify-content-center"
//           style={{ overflowX: "auto" }}
//         >
//           <Tabs
//             value={activeTab}
//             onChange={handleChange}
//             textColor="secondary"
//             indicatorColor="secondary"
//             variant="scrollable"
//             scrollButtons="auto"
//             allowScrollButtonsMobile
//             aria-label="scrollable auto tabs example"
//             TabIndicatorProps={{
//               sx: {
//                 height: "0px",
//                 overflow: "auto",
//               },
//             }}
//           >
//             <Tab
//               value="InProgress"
//               label="In Process"
//               sx={{
//                 fontSize: "17px",
//                 color: "#4a2a7d",
//                 fontWeight: "600",
//                 textTransform: "capitalize",
//               }}
//             />
//             <Tab
//               value="Executed"
//               label=" Executed"
//               sx={{
//                 fontSize: "17px",
//                 color: "#4a2a7d",
//                 fontWeight: "600",
//                 textTransform: "capitalize",
//               }}
//             />
//             <Tab
//               value="Expired"
//               label="Expired"
//               sx={{
//                 fontSize: "17px",
//                 color: "#4a2a7d",
//                 fontWeight: "600",
//                 textTransform: "capitalize",
//               }}
//             />
//           </Tabs>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-md-12">{renderTable()}</div>
//       </div>
//     </div>
//   );
// };

// export default Proposal;

// import React, { useState } from "react";
// import InProgressTable from "./Inprogresstable";
// import ExecutedTable from "./ExecutedTable";
// import ExpiredTable from "./ExpiredTable";
// import { useNavigate } from "react-router-dom";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import { Button } from "@mui/material";
// import axios from "axios";

// const Proposal = () => {
//   const Token = sessionStorage.getItem("token");
//   const [activeTab, setActiveTab] = useState("InProgress");

//   const renderTable = () => {
//     switch (activeTab) {
//       case "InProgress":
//         return <InProgressTable />;
//       case "Executed":
//         return <ExecutedTable />;
//       case "Expired":
//         return <ExpiredTable />;
//       default:
//         return null;
//     }
//   };

//   const navigate = useNavigate();

//   const handleCreatePurposal = async (id) => {
//     // try {
//     //   const url = `https://app.batuk.in/proposal_create/${id}`;
//     //   const headers = {
//     //     "Content-Type": "application/json",
//     //     Accept: "application/json",
//     //     Authorization: `Bearer ${Token}`,
//     //   };
//     //   const response = await axios.get(url, { headers });

//     //   console.log("prposal create response", response);
//     // } catch (error) {
//     //   console.error("Error connecting to API", error);
//     // }

//     navigate("/create_proposal");
//   };

//   const [value, setValue] = React.useState("InProgress");

//   const handleChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   console.log("value tabs", value);
//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div
//           className="col-md-12 ms-2 mt-2"
//           style={{ display: "flex", justifyContent: "flex-end" }}
//         >
//           <Button
//             variant="contained"
//             className="btn btn-primary"
//             onClick={handleCreatePurposal}
//             style={{ textTransform: "capitalize" }}
//           >
//             Create Order
//           </Button>
//         </div>
//       </div>

//       <div className="row  mt-2 p-2 d-flex justify-content-center">
//         <div className="scroll">
//           <div className="col-sm-12 d-flex justify-content-center">
//             <Tabs
//               value={activeTab}
//               onChange={handleChange}
//               textColor="secondary"
//               indicatorColor="secondary"
//               aria-label="secondary tabs example"
//               TabIndicatorProps={{
//                 sx: {
//                   height: "0px",
//                   overflow: "auto",
//                 },
//               }}
//             >
//               <Tab
//                 value="InProgress"
//                 label="In Process"
//                 sx={{
//                   fontSize: "17px",
//                   color: "#4a2a7d",
//                   fontWeight: "600",
//                   textTransform: "capitalize",
//                 }}
//               />
//               <Tab
//                 value="Executed"
//                 label=" Executed"
//                 sx={{
//                   fontSize: "17px",
//                   color: "#4a2a7d",
//                   fontWeight: "600",
//                   textTransform: "capitalize",
//                 }}
//               />
//               <Tab
//                 value="Expired"
//                 label="Expired"
//                 sx={{
//                   fontSize: "17px",
//                   color: "#4a2a7d",
//                   fontWeight: "600",
//                   textTransform: "capitalize",
//                 }}
//               />
//             </Tabs>
//           </div>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-md-12">{renderTable()}</div>
//       </div>
//     </div>
//   );
// };

// export default Proposal;
