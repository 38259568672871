import React, { useState, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Button, Modal, Box, Typography, IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/uploadFile.css";
import { baseUrl } from "../utils/baseUrl";
import { Link } from "react-router-dom";
import ImportFileTemplate from "../assets/Import_file/Bulk_Import_Investers.xlsx";
import { IoCloseCircleOutline } from "react-icons/io5";
import { formatDate } from "../utils/formatDate";
import { Col } from "react-bootstrap";
import { FaCloudDownloadAlt } from "react-icons/fa";

const UploadFile = () => {
  const token = sessionStorage.getItem("token");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [uploadResponseData, setUploadResponseData] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      setFileName(selectedFile.name);
      setFile(selectedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json(worksheet);
        setData(parsedData);
      };
      reader.readAsBinaryString(selectedFile);
    } else {
      toast.error("Please select a valid .xlsx file");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
  });

  const handleUpload = async () => {
    if (!file) {
      toast.error("Please select a file");
      return;
    }

    if (!token) {
      toast.error("Token not found. Please log in.");
      return;
    }

    if (!data || data.length === 0) {
      toast.error("No data found in the file");
      return;
    }

    const formatDate = (excelDate) => {
      if (!excelDate) return "";
      const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
      const day = String(jsDate.getDate()).padStart(2, "0");
      const month = String(jsDate.getMonth() + 1).padStart(2, "0");
      const year = jsDate.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const formattedData = data.map((row) => ({
      NAME: row.NAME || "",
      EMAIL: row.EMAIL || "",
      MOBILE: row.MOBILE || "",
      PAN: row.PAN || "",
      DATEOFBIRTH: formatDate(row["DATE OF BIRTH (DD/MM/YYYY)"]) || "",
      ADDRESS1: row["ADDRESS 1"] || "",
      ADDRESS2: row["ADDRESS 2"] || "",
      CITY: row.CITY || "",
      STATE: row.STATE || "",
      PIN: row.PIN || "",
      BANKACCOUNTNAME: row["BANK ACCOUNT NAME"] || "",
      IFSCCODE: row["IFSC CODE"] || "",
      BANKACCOUNTNUMBER: row["BANK ACCOUNT NUMBER"] || "",
    }));

    try {
      const url = `${baseUrl}/upload`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(url, formattedData, { headers });
      if (response.status === 200) {
        toast.success("File uploaded successfully");
        setUploadResponseData(response.data.data);
        setFileName("");
        setFile(null);
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the file");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFileName("");
    setUploadResponseData([]);
    setFile(null);
  };

  const excludeFields = ["error", "message"];

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Bulk Import Investors
            </h2>
          </Col>
          <div xs={6} style={{ width: "45%" }}>
            <Link
              to={ImportFileTemplate}
              download="Bulk_Import_Investers"
              target="_blank"
              style={{ display: "flex", justifyContent: "end", width: "100%" }}
            >
              <Button
                variant="contained"
                className="btn btn-primary"
                style={{ textTransform: "capitalize" }}
              >
                <FaCloudDownloadAlt size={20} /> &nbsp; Template
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-4">
        <div className="container">
          <div className="card card-import">
            <h3 style={{ color: "#4d16a4" }}>Import File</h3>
            <div className="drop_box" {...getRootProps()}>
              <input {...getInputProps()} />
              {!fileName ? (
                <>
                  <header>
                    <h4 style={{ color: "#4d16a4" }}>
                      Select file or Drag file here
                    </h4>
                  </header>
                  <p>Files Supported: .xlsx</p>
                  <button className="upload-btn">Choose File</button>
                </>
              ) : (
                <h4>{fileName}</h4>
              )}
            </div>
            {fileName && (
              <div className="form mt-3">
                <Button variant="contained" onClick={() => setOpenModal(true)}>
                  Preview File
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {uploadResponseData.length > 0 ? (
        <Modal
          open={openModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          disableEscapeKeyDown
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              overflow: "auto",
              maxHeight: "80vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2
                id="modal-title"
                className="EXCEL_HEADING"
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                Excel Data Preview
              </h2>
              <IconButton
                onClick={handleCloseModal}
                sx={{
                  color: "black",
                  fontSize: "20px", // Set icon size to 20px
                  "&:hover": {
                    color: "red",
                  },
                  transition: "color 0.3s ease",
                }}
              >
                <IoCloseCircleOutline />
              </IconButton>
            </div>

            <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
              {uploadResponseData.length > 0 ? (
                <table
                  className="data-table"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead>
                    <tr>
                      {Object.keys(uploadResponseData[0])
                        .filter((key) => !excludeFields.includes(key))
                        .map((key) => (
                          <th key={key} style={{ textAlign: "center" }}>
                            {key}
                          </th>
                        ))}
                      <th style={{ textAlign: "left", padding: "5px" }}>
                        MESSAGE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadResponseData.map((row, index) => (
                      <tr key={index}>
                        {Object.keys(row)
                          .filter((key) => !excludeFields.includes(key))
                          .map((key, idx) => (
                            <td key={idx} style={{ padding: "5px" }}>
                              {key === "DATEOFBIRTH (DD/MM/YYYY)"
                                ? formatDate(row[key])
                                : row[key]}{" "}
                            </td>
                          ))}

                        <td
                          style={{
                            color: row.error ? "red" : "green",
                            fontWeight: "bold",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          {row.error ? row.message : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  No data available for preview.
                </Typography>
              )}
            </div>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={openModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          disableEscapeKeyDown
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              overflow: "auto",
              maxHeight: "80vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2
                id="modal-title"
                className="EXCEL_HEADING"
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                Excel Data Preview
              </h2>
              <IconButton
                onClick={handleCloseModal}
                sx={{
                  color: "black",
                  fontSize: "20px",
                  "&:hover": {
                    color: "red",
                  },
                  transition: "color 0.3s ease",
                }}
              >
                <IoCloseCircleOutline />
              </IconButton>
            </div>

            <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
              {data.length > 0 ? (
                <table
                  className="data-table"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead>
                    <tr>
                      {data.length > 0 &&
                        Object.keys(data[0]).map((key) => (
                          <th key={key} style={{ textAlign: "center" }}>
                            {key}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        {Object.keys(row).map((key, idx) => (
                          <td key={idx} style={{ padding: "5px" }}>
                            {key === "DATEOFBIRTH (DD/MM/YYYY)"
                              ? formatDate(row[key])
                              : row[key]}{" "}
                          </td>
                        ))}
                        {row.error && (
                          <td
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              padding: "5px",
                            }}
                          >
                            {row.message}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  No data available for preview.
                </Typography>
              )}
            </div>

            <Button
              variant="contained"
              onClick={handleUpload}
              style={{
                marginTop: "10px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Upload
            </Button>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default UploadFile;

// import React, { useState, useCallback } from "react";
// import axios from "axios";
// import { useDropzone } from "react-dropzone";
// import { Button, Modal, Box, Typography, IconButton } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import * as XLSX from "xlsx";
// import "react-toastify/dist/ReactToastify.css";
// import "../assets/css/uploadFile.css";
// import { baseUrl } from "../utils/baseUrl";
// import { Link } from "react-router-dom";
// import ImportFileTemplate from "../assets/Import_file/Import_File_Template.xlsx";
// import { IoCloseCircleOutline } from "react-icons/io5";
// import { formatDate } from "../utils/formatDate";
// import { NULL } from "sass";

// const UploadFile = () => {
//   const token = sessionStorage.getItem("token");
//   const [fileName, setFileName] = useState("");
//   const [file, setFile] = useState(null);
//   const [data, setData] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [uploadResponseData, setUploadResponseData] = useState([]);

//   const onDrop = useCallback((acceptedFiles) => {
//     const selectedFile = acceptedFiles[0];
//     if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
//       setFileName(selectedFile.name);
//       setFile(selectedFile);

//       // Parse file immediately for preview
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const binaryStr = e.target.result;
//         const workbook = XLSX.read(binaryStr, { type: "binary" });
//         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
//         const parsedData = XLSX.utils.sheet_to_json(worksheet);
//         setData(parsedData);
//       };
//       reader.readAsBinaryString(selectedFile);
//     } else {
//       toast.error("Please select a valid .xlsx file");
//     }
//   }, []);

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: ".xlsx",
//   });

//   console.log(uploadResponseData, "response Data>>>>>>>>>>>>");

//   const handleUpload = async () => {
//     if (!file) {
//       toast.error("Please select a file");
//       return;
//     }

//     if (!token) {
//       toast.error("Token not found. Please log in.");
//       return;
//     }

//     if (!data || data.length === 0) {
//       toast.error("No data found in the file");
//       return;
//     }

//     // Helper function to format Excel date serial numbers
//     const formatDate = (excelDate) => {
//       if (!excelDate) return "";
//       const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
//       const day = String(jsDate.getDate()).padStart(2, "0");
//       const month = String(jsDate.getMonth() + 1).padStart(2, "0");
//       const year = jsDate.getFullYear();
//       return `${day}/${month}/${year}`;
//     };

//     // Mapping all rows to match the API structure
//     const formattedData = data.map((row) => ({
//       NAME: row.NAME || "",
//       EMAIL: row.EMAIL || "",
//       MOBILE: row.MOBILE || "",
//       PAN: row.PAN || "",
//       DATEOFBIRTH: formatDate(row["DATEOFBIRTH (DD/MM/YYYY)"]) || "",
//       ADDRESS1: row["ADDRESS 1"] || "",
//       ADDRESS2: row["ADDRESS 2"] || "",
//       CITY: row.CITY || "",
//       STATE: row.STATE || "",
//       PIN: row.PIN || "",
//       BANKACCOUNTNAME: row["BANK ACCOUNT NAME"] || "",
//       IFSCCODE: row["IFSC CODE"] || "",
//       BANKACCOUNTNUMBER: row["BANK ACCOUNT NUMBER"] || "",
//     }));

//     try {
//       const url = `${baseUrl}/upload`;
//       const headers = {
//         Authorization: `Bearer ${token}`,
//       };

//       // Sending all rows in the request body
//       const response = await axios.post(url, formattedData, { headers });
//       if (response.status === 200) {
//         toast.success("File uploaded successfully");
//         setUploadResponseData(response.data.data);
//         setFileName("");
//         setFile(null);
//       } else {
//         toast.error("File upload failed");
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       toast.error("An error occurred while uploading the file");
//     }
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setFileName("");
//     setUploadResponseData([]);
//     setFile(null);
//   };

//   const excludeFields = ["error", "message"];

//   return (
//     <div className="container-fluid">
//       <ToastContainer />
//       <div className="row">
//         <div
//           className="col-md-12 ms-2 mt-2"
//           style={{ display: "flex", justifyContent: "flex-start" }}
//         >
//           <Link
//             to={ImportFileTemplate}
//             download="ImportFileTemplate"
//             target="_blank"
//           >
//             <Button
//               variant="contained"
//               className="btn btn-primary"
//               style={{ textTransform: "capitalize" }}
//             >
//               Download Template
//             </Button>
//           </Link>
//         </div>
//       </div>
//       <div className="row mt-4">
//         <div className="container">
//           <div className="card card-import">
//             <h3 style={{ color: "#4d16a4" }}>Import File</h3>
//             <div className="drop_box" {...getRootProps()}>
//               <input {...getInputProps()} />
//               {!fileName ? (
//                 <>
//                   <header>
//                     <h4 style={{ color: "#4d16a4" }}>
//                       Select file or Drag file here
//                     </h4>
//                   </header>
//                   <p>Files Supported: .xlsx</p>
//                   <button className="upload-btn">Choose File</button>
//                 </>
//               ) : (
//                 <h4>{fileName}</h4>
//               )}
//             </div>
//             {fileName && (
//               <div className="form mt-3">
//                 <Button variant="contained" onClick={() => setOpenModal(true)}>
//                   Preview File
//                 </Button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {/* Modal to display data */}
//       {uploadResponseData.length > 0 ? (
//         <Modal
//           open={openModal}
//           aria-labelledby="modal-title"
//           aria-describedby="modal-description"
//           disableEscapeKeyDown
//         >
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: "80%",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               p: 4,
//               borderRadius: "8px",
//               overflow: "auto",
//               maxHeight: "80vh", // This ensures the modal doesn't take up the entire screen height
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2
//                 id="modal-title"
//                 className="EXCEL_HEADING"
//                 style={{
//                   textAlign: "center",
//                   margin: "0 auto",
//                   fontSize: "1.5rem",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Excel Data Preview
//               </h2>
//               <IconButton
//                 onClick={handleCloseModal}
//                 sx={{
//                   color: "black",
//                   fontSize: "20px", // Set icon size to 20px
//                   "&:hover": {
//                     color: "red",
//                   },
//                   transition: "color 0.3s ease",
//                 }}
//               >
//                 <IoCloseCircleOutline />
//               </IconButton>
//             </div>

//             <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
//               {uploadResponseData.length > 0 ? (
//                 <table
//                   className="data-table"
//                   style={{ width: "100%", borderCollapse: "collapse" }}
//                 >
//                   <thead>
//                     <tr>
//                       {Object.keys(uploadResponseData[0])
//                         .filter((key) => !excludeFields.includes(key)) // Exclude specified fields
//                         .map((key) => (
//                           <th key={key} style={{ textAlign: "center" }}>
//                             {key}
//                           </th>
//                         ))}
//                       {/* Add a column for error messages */}
//                       <th style={{ textAlign: "left", padding: "5px" }}>
//                         MESSAGE
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {uploadResponseData.map((row, index) => (
//                       <tr key={index}>
//                         {Object.keys(row)
//                           .filter((key) => !excludeFields.includes(key)) // Ensure fields are excluded in data rows
//                           .map((key, idx) => (
//                             <td key={idx} style={{ padding: "5px" }}>
//                               {key === "DATEOFBIRTH (DD/MM/YYYY)"
//                                 ? formatDate(row[key])
//                                 : row[key]}{" "}
//                             </td>
//                           ))}

//                         {/* Add a column for error messages */}
//                         <td
//                           style={{
//                             color: row.error ? "red" : "green",
//                             fontWeight: "bold",
//                             padding: "5px",
//                             textAlign: "center",
//                           }}
//                         >
//                           {row.error ? row.message : ""}
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               ) : (
//                 <Typography
//                   variant="body1"
//                   color="textSecondary"
//                   style={{ textAlign: "center", marginTop: "20px" }}
//                 >
//                   No data available for preview.
//                 </Typography>
//               )}
//             </div>
//           </Box>
//         </Modal>
//       ) : (
//         <Modal
//           open={openModal}
//           aria-labelledby="modal-title"
//           aria-describedby="modal-description"
//           disableEscapeKeyDown
//         >
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: "80%",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               p: 4,
//               borderRadius: "8px",
//               overflow: "auto",
//               maxHeight: "80vh", // This ensures the modal doesn't take up the entire screen height
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2
//                 id="modal-title"
//                 className="EXCEL_HEADING"
//                 style={{
//                   textAlign: "center",
//                   margin: "0 auto",
//                   fontSize: "1.5rem",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Excel Data Preview
//               </h2>
//               <IconButton
//                 onClick={handleCloseModal}
//                 sx={{
//                   color: "black",
//                   fontSize: "20px", // Set icon size to 20px
//                   "&:hover": {
//                     color: "red",
//                   },
//                   transition: "color 0.3s ease",
//                 }}
//               >
//                 <IoCloseCircleOutline />
//               </IconButton>
//             </div>

//             <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
//               {data.length > 0 ? (
//                 <table
//                   className="data-table"
//                   style={{ width: "100%", borderCollapse: "collapse" }}
//                 >
//                   <thead>
//                     <tr>
//                       {data.length > 0 &&
//                         Object.keys(data[0]).map((key) => (
//                           <th key={key} style={{ textAlign: "center" }}>
//                             {key}
//                           </th>
//                         ))}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {data.map((row, index) => (
//                       <tr key={index}>
//                         {Object.keys(row).map((key, idx) => (
//                           <td key={idx} style={{ padding: "5px" }}>
//                             {key === "DATEOFBIRTH (DD/MM/YYYY)"
//                               ? formatDate(row[key])
//                               : row[key]}{" "}
//                           </td>
//                         ))}
//                         {/* Display message based on error */}
//                         {row.error && (
//                           <td
//                             style={{
//                               color: "red",
//                               fontWeight: "bold",
//                               padding: "5px",
//                             }}
//                           >
//                             {row.message}
//                           </td>
//                         )}
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               ) : (
//                 <Typography
//                   variant="body1"
//                   color="textSecondary"
//                   style={{ textAlign: "center", marginTop: "20px" }}
//                 >
//                   No data available for preview.
//                 </Typography>
//               )}
//             </div>

//             <Button
//               variant="contained"
//               onClick={handleUpload}
//               style={{
//                 marginTop: "10px",
//                 display: "block",
//                 marginLeft: "auto",
//                 marginRight: "auto",
//               }}
//             >
//               Upload
//             </Button>
//           </Box>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default UploadFile;
