import React, { useState } from "react";
import OrderInProgressTable from "./OrderInProgressTable";
import OrderHistoryTable from "./OrderHistoryTable";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "@mui/material";
import OrderCancelTable from "./OrderCancelTable";
import { Col } from "react-bootstrap";
const SpotGold = () => {
  const [activeTab, setActiveTab] = useState("OrderInProgress");

  const renderTable = () => {
    switch (activeTab) {
      case "OrderInProgress":
        return <OrderInProgressTable />;
      case "OrderHistory":
        return <OrderHistoryTable />;
      case "OrderCancelled":
        return <OrderCancelTable />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  const handleCreateOrder = async (id) => {
    navigate("/dashboard/create_order");
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Jewellery Order
            </h2>
          </Col>
          <div
            xs={6}
            style={{ width: "45%", display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              className="btn btn-primary"
              onClick={handleCreateOrder}
              style={{ textTransform: "capitalize" }}
            >
              + Create Order
            </Button>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-2 p-2 d-flex justify-content-center">
        <div
          className="col-sm-12 d-flex justify-content-center"
          style={{ overflowX: "auto", gap: "2rem" }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              sx: {
                height: "0px",
                overflow: "auto",
              },
            }}
          >
            <Tab
              value="OrderInProgress"
              label="In-Progress"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "OrderInProgress" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="OrderHistory"
              label="History"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "OrderHistory" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="OrderCancelled"
              label="Cancelled"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "OrderCancelled" ? "#dcdaff" : "inherit",
              }}
            />
          </Tabs>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12">{renderTable()}</div>
      </div>
    </div>
  );
};

export default SpotGold;
